body{
    min-height: 100vh;
    background-color: #ffffff;
}

.bharatGPT .custom-scrollbar{
    transition: none!important;
}

.bharatGPT .container-fluid{
    height: auto;
    overflow: hidden;
}

.bharatGPT .call-chat-sidebar{
    max-width: 260px!important;
}

.bharatGPT .sideBarMain{
    background-color: #f18213;;
}

.bharatGPT .sideBar{
    background-color: #f18213;
    display: grid;
    grid-template-rows: 80px 1fr 91px;
    height: auto;
    overflow: hidden;
    margin: 0px;
    border-radius: 0px;
    position: fixed;
    bottom: 0;
    width: 260px;
    height: 100vh;
    z-index: 99;
    overflow: hidden;
    overflow-y: auto;
}

.bharatGPT .newChatButton{
    border: 1px solid #FFFFFF33;
    font-size: 16px;
    padding: 5px 0px;
    text-align: center;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    margin-bottom: 30px;
    color: #fff;
}

.bharatGPT .sideBarHeader{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    background-color: #f18213;
    position: fixed;
    width: 100%;
    top: 0;
    padding-top: 10px;
    max-width: 240px;
}

.bharatGPT .sideBarHeaderCloseButton{
    text-align: center;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    display: none;
    color: #fff;
}

.bharatGPT .sideBarHeaderCloseButton .icon{
    font-size: 30px;
    margin-top: 7px;
}

.bharatGPT .newChatButton .icon, .newChatButton p{
    margin-right: 10px;
    font-size: 16px;
}

.bharatGPT .newChatButton:hover{
    background-color: #6C7A921A;
}

.bharatGPT .chatRecentSearch{
    overflow:hidden;
    overflow-y:auto;
    height: max-content;
    margin-bottom: 120px;
    margin-top: 50px;
}

.bharatGPT .chatRecentSearchBox{
    cursor:pointer;
    margin-top:20px;
    display:grid;
    grid-template-columns:25px 1fr 40px;
    color: #fff;
}

.bharatGPT .chatRecentSearchBox p{
    margin: 0px;
    margin-right: 5px!important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.bharatGPT .chatRecentSearchBox{
    padding: 10px 0px;
}

.bharatGPT .chatRecentSearchBox:hover{
    background-color: #6C7A921A;
}

.bharatGPT .chatRecentSearchDeleteIcon{
    color: transparent;
    cursor: pointer;
    margin-top: 3px;
    font-size: 18px;
    margin-right: 5px;
}

.bharatGPT .chatRecentSearchBox:hover .chatRecentSearchDeleteIcon {
    color: #ffffff;
}

.bharatGPT .chatRecentSearchDeleteIcon:hover{
    margin-right: 20px;
}

.bharatGPT .sideBarFooter{
    border-top: 1px solid #6C7A921A;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 240px!important;
    background-color: #f18213;
    /* padding: 0px 10px; */
}

.bharatGPT .chatClearConversations{
    font-size: 14px;
    padding: 5px 0px; 
    text-align: center;
    display: inline-flex; 
    align-items: center; 
    width: 100%; 
    cursor: pointer; 
    margin: 0px; 
    margin-bottom: 10px; 
    margin-top: 10px;
}

.bharatGPT .sideBarLogout{
    font-size: 16px; 
    padding: 5px 0px; 
    text-align: center; 
    display: inline-flex; 
    align-items: center; 
    width: 100%; 
    cursor: pointer; 
    margin: 0px; 
    margin-bottom: 10px;
}

.bharatGPT .chatClearConversations p, .sideBarLogout p{
    font-size: 14px;
}

.bharatGPT .chatClearConversations:hover, .sideBarLogout:hover{
    background-color: #6C7A921A;
}

.bharatGPT .chatClearConversations .icon, .sideBarLogout .icon{
    margin-top: -2px;
    margin-right: 5px;
}

.bharatGPT .chat-history{
    height: auto!important;
    margin-top: 50px!important;
}

.bharatGPT .chatAreaMain{
    padding: 0px;
}

.bharatGPT .chatAreaMain .card{
    border-radius: 0px;
}

.bharatGPT .chat-header{
    padding: 7px 20px!important;
    position: fixed;
    top: 0;
    width: calc(100% - 260px);
    background-color: #ffffff;
    z-index: 9;
    margin-left: 10px;
}

.bharatGPT .chat-header .rounded-circle{
    width: 35px!important;
    height: 35px!important;
}

.bharatGPT .chat-header .about{
    margin-top: 8px;
    padding-left: 0px!important;
}

.bharatGPT .chat-header  .mobileMenuContainer {
    display: none;
}

.bharatGPT .chat-message{
    padding: 5px 10px!important;
    width: 100%!important;
    position: fixed;
    bottom: 5px;
    position: fixed!important;
    bottom: 0;
    max-width: calc(100% - 260px);
}

.bharatGPT .call-chat-body .chat-box{
    height: auto!important;
}

.bharatGPT .sendButton{
    font-size: 25px;
    margin-left: 10px;
}

.bharatGPT .sendButton .icon{
    margin-top: 10px;
    color: #8e8ea0;
}

.bharatGPT .sendButton .icon:hover{
    color: #138808;
    cursor: pointer;
}

.bharatGPT .chatMessageBox{
    padding: 0px!important;
}

.bharatGPT .chat-message input{
    border: 2px solid #6C7A921A!important;
}

.bharatGPT .chat-message .iconBox{
    background-color: #eff0f1!important;
    padding: 10px 7px!important;
}

.bharatGPT .chat-message .iconBox .picker{
    padding: 0px;
}

.bharatGPT .chat-message .iconBox img{
    width: 27px!important;
    cursor: pointer;
}

.bharatGPT .emoji-mart-bar{
    display: none!important;
}

.bharatGPT .chat-message div{
    padding: 0px 20px;
}

.bharatGPT .chat-message .input-group{
    padding: 0px!important;
}

.bharatGPT .chat-message .input-group .input-group-append{
    padding: 0px!important;
}

.bharatGPT .chat-box .chat-right-aside .chat .chat-msg-box .message{
    white-space: break-spaces;
}

.bharatGPT .chat-message .sendButton{
    padding: 0px!important;
}

.bharatGPT .chat-message input{
    border-radius: 7px!important;
}

.bharatGPT .chatMessageBox{
    padding: 0px;
}

.bharatGPT .chat-box .chat-right-aside .chat .chat-msg-box .message{
    width: calc(100% - 15px)!important;
}

.bharatGPT .chat-history ul li{
    padding: 0px 100px;
}

.bharatGPT .message .message-data{
    margin-top: -29px!important;
    margin-bottom: 10px;
}

.bharatGPT .message .message-data .message-data-time, .message-data-user{
    margin-left: 40px;
    background: #fff;
    padding: 0px 8px;
}

.bharatGPT .chatHistoryLoading{
    margin-top: 20px!important;
    padding: 0px 100px!important;
    margin-right: 15px;
}

.bharatGPT .chatRecentLoadingBox{
    margin-top: auto!important;
    text-align: center;
}

.bharatGPT .chat-right-aside{
    max-width: 100%;
    flex: 0 0 100%;
}

.bharatGPT .message .chat-user-img{
    width: 35px!important;
    height: 35px!important;
}

.bharatGPT .chat-user-img-user{
    display: block!important;
    background: #138808!important;
}

.bharatGPT .message-data-user{
    margin-top: -30px!important;
    width: fit-content;
    margin-bottom: 10px!important;
}

.bharatGPT .chat-user-img-user div{
    font-size: 16px!important;
}

.bharatGPT .chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img{
    margin-top: -34px!important;
}

.bharatGPT .chat-box .chat-right-aside .chat .chat-header img{
    width: auto!important;
    height: auto!important;
    max-width: 200px;
    max-height: 30px;
    box-shadow: unset!important;
    margin-right: 10px;
    margin-top: 4px;
}

@media screen and (min-width: 1380px) {
    .bharatGPT .message .message-data{
        margin-top: -34px!important;
    }

    .bharatGPT .message-data-user{
        margin-top: -30px!important;
    }

    .bharatGPT .chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img{
        margin-top: -39px!important;
    }
}

@media screen and (max-width: 1180px) {
    .bharatGPT .chat-history ul li{
        padding: 0px;
    }

    .bharatGPT .chatHistoryLoading{
        padding: 0px;
    }

    .bharatGPT .chat-box .chat-right-aside .chat .chat-msg-box .message{
        width: 100%!important;
    }

    .bharatGPT .chatHistoryLoading{
        margin-right: 5px!important;
        margin-left: 5px!important;
        padding: 0px!important;
    }
}

@media screen and (max-width: 766px) {

    .bharatGPT .chat-box .chat-right-aside .chat .chat-header img{
        max-width: 100px!important;
    }

    .bharatGPT .sideBarMain{
        display: none;
    }

    .bharatGPT .sideBarHeader{
        grid-template-columns: 1fr 70px;
        max-width: 100%!important;
    }

    .bharatGPT .sideBarHeaderCloseButton{
        display: block;
    }

    .bharatGPT .chat-right-aside{
        max-width: 100%!important;
        padding: 0px!important;
    }

    .bharatGPT .chat-history{
        width: 100%;
        height: auto!important;
    }

    .bharatGPT .chatAreaMain{
        padding: 0px;
    }

    .bharatGPT .chat-header{
        padding: 7px 20px!important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%!important;
    }
    
    .bharatGPT .chat-header .rounded-circle{
        width: 25px!important;
        height: 25px!important;
    }

    .bharatGPT .chat-header .about{
        margin-top: 3px;
    }
      
    .bharatGPT .chat-header  .logo_header {
        display: flex;
        align-items: center;
        width: calc(100% - 40px)!important;
        margin-top: -3px!important;
    }

    .bharatGPT .chat-header  .logo_header .status{
        display: none;
    }
      
    .bharatGPT .chat-header  .mobileMenuContainer {
        display: flex;
        align-items: center;
    }

    .bharatGPT .chat-header .mobileMenuContainer .mobileMenu{
        height: 22px!important;
        cursor: pointer;
        color: #8e8ea0;
    }

    .bharatGPT .chat-message{
        width: 100%!important;
        margin-left: 12px!important;
        max-width: 100%!important;
    }

    .bharatGPT .chat-message div{
        padding: 0px;
    }

    .bharatGPT .chat-message .chatMessageBox{
        padding: 0px 10px!important;
    }

    .bharatGPT .chatMessageBox .iconBox{
        display: none!important;
    }

    .bharatGPT .chat-message .chatMessageBox .input-group{
        padding: 0px 10px;
    }

    .bharatGPT .chat-message .chatMessageBox input{
        border-radius: 7px!important;
    }

    .bharatGPT .chat-message .chatMessageBox .input-group .input-group-append{
        padding: 0px;
    }

    .bharatGPT .chat-message .sendButton{
        padding: 0px;
        margin-left: 4px;
    }

    .bharatGPT .chat-box .chat-right-aside .chat .chat-msg-box .message{
        width: 100%!important;
    }

    .bharatGPT .chatHistoryLoading{
        margin-right: 5px!important;
        margin-left: 5px!important;
        padding: 0px!important;
    }

    .bharatGPT .sideBar{
        width: 100%!important;
    }

    .bharatGPT .sideBarFooter{
        width: 100%!important;
        max-width: calc(100% - 20px)!important;
    }

    .bharatGPT .chatRecentSearch{
        margin-top: 140px;
    }
}