// PhoneInputInput
.PhoneInputInput{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.PhoneInputInput:focus{
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(12, 110, 253, 0.25);
}

// End PhoneInputInput

